import { Link } from 'gatsby';
import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Disqus } from 'gatsby-plugin-disqus';
import Bio from './Bio';
import Layout from './Layout';
import Tag from './Tag';
import { rhythm, scale } from '../utils/typography';
import { H1 } from './typography/Heading';

type Frontmatter = {
  title: string
  path: string
  date: string
  tags: string
  description: string
}

export type Post = {
  id: string
  frontmatter: Frontmatter
  body: string
}

type DisqusConfig = {
  url: string,
  identifier: string,
  title: string
}

export type PageContext = {
  frontmatter: Frontmatter
  fields: {
    slug: string
  }
}

type BlogBodyProps = {
  location: string
  siteTitle: string
  post: Post
  disqusConfig: DisqusConfig
  next: PageContext
  previous: PageContext
}

const BlogBody = (props: BlogBodyProps): JSX.Element => (
  <Layout location={props.location} title={props.siteTitle}>
    <H1>{props.post.frontmatter.title}</H1>
    <span
      style={{
        display: 'block',
        marginBottom: 30,
      }}
    >
      {props.post.frontmatter.tags.split(', ').map((tag) => <Tag key={tag} tagType={tag} />)}
    </span>
    <p
      style={{
        ...scale(-1 / 5),
        display: 'block',
        marginBottom: rhythm(1),
        marginTop: rhythm(-1),
      }}
    >
      {props.post.frontmatter.date}
    </p>
    <MDXRenderer>{props.post.body}</MDXRenderer>

    <hr
      style={{
        marginBottom: rhythm(1),
      }}
    />

    <Disqus config={props.disqusConfig} />

    <Bio />

    <ul
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        listStyle: 'none',
        padding: 0,
        marginTop: 15,
      }}
    >
      <li>
        {props.previous && (
          <Link to={`/blog${props.previous.fields.slug}`} rel="prev">
            ←
            {' '}
            {props.previous.frontmatter.title}
          </Link>
        )}
      </li>
      <li>
        {props.next && (
          <Link to={`/blog${props.next.fields.slug}`} rel="props.next">
            {props.next.frontmatter.title}
            {' '}
            →
          </Link>
        )}
      </li>
    </ul>
  </Layout>
);

export default BlogBody;
