import * as React from 'react';
import { graphql } from 'gatsby';
import BlogBody, { PageContext, Post } from '../components/BlogBody';

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
      }
    }
  }
`;

const isBrowser = typeof window !== 'undefined';

export type BlogPostProps = {
  path: string
  location: string
  data: {
    site: {
      siteMetadata: {
        siteUrl: string
        title: string
      }
    }
    mdx: Post
  }
  pageContext: {
    next: PageContext
    previous: PageContext
  }
}

const BlogPost = (props: BlogPostProps): React.ReactElement => {
  if (!isBrowser) return null;

  const { siteUrl } = props.data.site.siteMetadata;
  const postPath = props.path;
  const post = props.data.mdx;

  const disqusConfig = {
    url: `${siteUrl}${postPath}`,
    identifier: post.id,
    title: post.frontmatter.title,
  };

  const siteTitle = props.data.site.siteMetadata.title;
  const { previous, next } = props.pageContext;

  return (
    <BlogBody
      location={props.location}
      siteTitle={siteTitle}
      previous={previous}
      next={next}
      disqusConfig={disqusConfig}
      post={post}
    />
  );
};

export default BlogPost;
